import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";

const TermsOfSale = () => (
  <Index>
    <Seo title="Terms of Sale" />
    <Container>
      <p>&nbsp;</p>
      <h1 className="text-blue align-center">Terms of Use</h1>
      <p className="lead text-blue font-light align-center">Coming soon</p>
      <p>&nbsp;</p>
    </Container>
  </Index>
)

export default TermsOfSale
